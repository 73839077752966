import React from 'react';
import PropTypes from 'prop-types';
import CccisdToggle from 'cccisd-toggle';
import Notification from 'cccisd-notification';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import { addEvent } from 'cccisd-laravel-eventlog';
import { client as apollo, gql } from 'cccisd-apollo';
import Tabs from 'cccisd-tabs';
import Confirm from 'cccisd-confirm';
import style from './style.css';

import FeedbackTable from './FeedbackTable';
import InterventionPlanning from './InterventionPlanning';
import Select from './Select';
import Goals from './Goals';
import ActivityLog from './ActivityLog';

import _omit from 'lodash/omit';
import _isEqual from 'lodash/isEqual';

import add from 'date-fns/add_days';
import isAfter from 'date-fns/is_after';
import format from 'date-fns/format';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

export default class CheckUpFeedbackCoach extends React.Component {
    static propTypes = {
        /**
         * content json for view
         */
        content: PropTypes.object.isRequired,
        match: PropTypes.object,
    };

    state = {
        userList: [],
        loading: true,
        shareReport: false,
        goals: {},
        goalChanges: 0,
    };

    // LIFE CYCLE
    componentDidMount = async () => {
        window.addEventListener('beforeunload', this.handleUnload);

        let paramId;

        if (this.props.match.params.id && this.props.match.params.id !== ':id') {
            paramId = this.props.match.params.id;
        }

        const res = await this.getUserList();

        if (res) {
            const userList = this.processUserList(res.data.roles);

            const selectedPerson = paramId
                ? userList.find(item => +item.value === +paramId)
                : userList[0];

            const metrics = await this.getMetrics(
                selectedPerson.value,
                selectedPerson.deploymentId,
                selectedPerson.assignmentId
            );

            const dataObj = this.processQueryData(metrics);

            this.setState({
                loading: false,
                userList,
                selectedPerson,
                feedbackMetrics: metrics,
                ...dataObj,
                // dropDownContent: formattedMessages.length > 0 ? formattedMessages : null,
            });
        }
    };

    canBeShared = () => {
        const { selectedPerson } = this.state;
        if (selectedPerson) {
            return (
                this.state.selectedPerson.role === 'teacher' &&
                this.state.selectedPerson.submittedBy !== 'teacher' &&
                this.state.selectedPerson.submittedBy !== null
            );
        }
        return false;
    };

    noDevTags = () => {
        const { feedbackMetrics } = this.state;
        if (feedbackMetrics) {
            const devTags = feedbackMetrics.data.roles.anyRole.assignmentProgress.devTags;
            const devTagArr = Object.keys(devTags).map(item => {
                return {
                    value: devTags[item],
                    key: item,
                };
            });

            const noDevTags = devTagArr
                .filter(item => item.value !== 'MetricsDevTags')
                .every(item => item.value === null || item.value === '');

            return noDevTags;
        }
        return true;
    };

    // Process Data
    processQueryData = metrics => {
        const assignmentProgress = metrics.data.roles.anyRole.assignmentProgress;

        const devTags = metrics.data.roles.anyRole.assignmentProgress.devTags;
        const reportShared = devTags.ShareReport === '1' ? true : false;

        const goalsMetrics = assignmentProgress.goals;
        const initialGoals = this.processGoals(goalsMetrics);
        const propGoals = this.getGoalsFromProps();

        const dataObj = {
            shareReport: reportShared,
            initialGoals: initialGoals ? initialGoals : propGoals,
            goals: initialGoals ? initialGoals : propGoals,
        };
        return dataObj;
    };

    processUserList = data => {
        if (data) {
            const coachFlowProgress = data.coach.coachFlowProgress;
            const coachAssignmentProgress = data.coach.assignmentProgressList;

            const coachStartedFlow = coachFlowProgress.find(
                item => item.completed === true && item.flowHandle === 'survey32'
            );

            const coachCompletedFlow = coachFlowProgress.find(
                item =>
                    item.completed === true &&
                    item.flowHandle === 'survey24' &&
                    item.responseSet === coachStartedFlow.responseSet
            );

            const coachPrevCompletedFlow = coachFlowProgress.find(
                item => item.completed === true && item.flowHandle === 'survey24'
            );

            let coachAssignmentCompleted;
            let coachAssignmentInProgress;

            if (coachStartedFlow && coachCompletedFlow) {
                coachAssignmentCompleted = coachAssignmentProgress.find(
                    item => item.deployment.deploymentId === coachCompletedFlow.responseSet
                );
            }
            if (coachStartedFlow && !coachCompletedFlow) {
                coachAssignmentInProgress = coachAssignmentProgress.find(
                    item => item.deployment.deploymentId === coachStartedFlow.responseSet
                );
                if (coachPrevCompletedFlow) {
                    coachAssignmentCompleted = coachAssignmentProgress.find(
                        item => item.deployment.deploymentId === coachPrevCompletedFlow.responseSet
                    );
                }
            }

            let expirationDateCoach;
            let deploymentIdCoach;
            let assignmentIdCoach;
            let assignmentProgressIdCoach;
            let coachIsNew;
            let completedDateCoach;
            // inProgress
            if (coachAssignmentInProgress) {
                if (coachAssignmentCompleted) {
                    // Has Progress && Completed
                    const startedDate = this.getDate(coachStartedFlow.startedAt);
                    completedDateCoach = this.getDate(coachPrevCompletedFlow.completedAt);
                    expirationDateCoach = add(startedDate, 30);

                    deploymentIdCoach = coachAssignmentCompleted.deployment.deploymentId;
                    assignmentIdCoach = coachAssignmentCompleted.deployment.assignment.assignmentId;
                    assignmentProgressIdCoach = coachAssignmentCompleted.assignmentProgressId;
                } else {
                    // Has Progress / No Completed
                    const startedDate = this.getDate(coachStartedFlow.startedAt);
                    expirationDateCoach = add(startedDate, 30);

                    deploymentIdCoach = coachAssignmentInProgress.deployment.deploymentId;
                    assignmentIdCoach =
                        coachAssignmentInProgress.deployment.assignment.assignmentId;
                    assignmentProgressIdCoach = coachAssignmentInProgress.assignmentProgressId;
                }

                // NOT IN PROGRESS
            } else if (!coachAssignmentInProgress) {
                // Completed
                if (coachAssignmentCompleted) {
                    const startedDate = this.getDate(coachCompletedFlow.startedAt);
                    completedDateCoach = this.getDate(coachCompletedFlow.completedAt);
                    expirationDateCoach = add(startedDate, 30);

                    deploymentIdCoach = coachAssignmentCompleted.deployment.deploymentId;
                    assignmentIdCoach = coachAssignmentCompleted.deployment.assignment.assignmentId;
                    assignmentProgressIdCoach = coachAssignmentCompleted.assignmentProgressId;
                } else {
                    //      No Completed / NO Data At all
                    coachIsNew = true;
                }
            }

            const coach = [
                {
                    value: +data.coach.pawn.pawnId,
                    name: data.coach.user.fullName,
                    pawn: {
                        pawnId: +data.coach.pawn.pawnId,
                        pawnHash: data.coach.pawn.pawnHash,
                        firstName: data.coach.user.firstName,
                        lastName: data.coach.user.lastName,
                        username: data.coach.user.username,
                        respondentHash: data.coach.pawn.respondentHash,
                    },
                    parentPawns: data.coach.parentRoles.anyRoleList,
                    pendingInvites: data.coach.pendingRequestToMeList,

                    label: data.coach.user.fullName + ' (Myself)',
                    role: data.coach.pawn.role,
                    surveyInProgress: coachAssignmentInProgress ? true : false,
                    expirationDate: format(expirationDateCoach, 'MM/DD/YYYY'),
                    isExpired: isAfter(new Date(), expirationDateCoach),
                    deploymentId: deploymentIdCoach,
                    assignmentId: assignmentIdCoach,
                    assignmentProgressId: assignmentProgressIdCoach,
                    isNew: coachIsNew ? coachIsNew : false,
                    completedDate: completedDateCoach,
                    coach: null,
                },
            ];

            const teacherList = data.teacherList.map(item => {
                const teacherFlowProgress = item.teacherFlowProgress;
                const teacherAssignmentProgress = item.assignmentProgressList;

                const teacherFlowInProgress = teacherFlowProgress.find(
                    thing => thing.completed === true && thing.flowHandle === 'survey36'
                );

                const teacherFlowCompleted = teacherFlowProgress.find(
                    thing =>
                        thing.completed === true &&
                        thing.flowHandle === 'survey39' &&
                        thing.responseSet === teacherFlowInProgress.responseSet
                );

                const teacherPrevCompleted = teacherFlowProgress.find(
                    thing => thing.completed === true && thing.flowHandle === 'survey39'
                );

                let teacherAssignmentCompleted;
                let teacherAssignmentInProgress;

                if (teacherFlowInProgress && teacherFlowCompleted) {
                    teacherAssignmentCompleted = teacherAssignmentProgress.find(
                        thing => thing.deployment.deploymentId === teacherFlowCompleted.responseSet
                    );
                }

                if (teacherFlowInProgress && !teacherFlowCompleted) {
                    teacherAssignmentInProgress = teacherAssignmentProgress.find(
                        thing => thing.deployment.deploymentId === teacherFlowInProgress.responseSet
                    );
                    if (teacherPrevCompleted) {
                        teacherAssignmentCompleted = teacherAssignmentProgress.find(
                            thing =>
                                thing.deployment.deploymentId === teacherPrevCompleted.responseSet
                        );
                    }
                }

                let expirationDateTeacher;
                let deploymentIdTeacher;
                let assignmentIdTeacher;
                let assignmentProgressIdTeacher;
                let teacherIsNew;
                let completedDateTeacher;
                let submittedBy;
                let isExpired = false;

                const currentCoach = +Fortress.user.acting.id;
                const teacherId = +item.pawn.pawnId;

                if (teacherAssignmentInProgress) {
                    const progressSubmittedBy = +teacherAssignmentInProgress.devTags.SubmittedBy;
                    if (teacherAssignmentCompleted) {
                        if (teacherId === progressSubmittedBy) {
                            submittedBy = 'teacher';
                        } else if (currentCoach === progressSubmittedBy) {
                            submittedBy = 'coach';
                        } else {
                            isExpired = true;
                            submittedBy = null;
                        }

                        const startedDate = this.getDate(teacherFlowInProgress.startedAt);
                        completedDateTeacher = this.getDate(teacherPrevCompleted.completedAt);
                        expirationDateTeacher = add(startedDate, 30);
                        deploymentIdTeacher = teacherAssignmentCompleted.deployment.deploymentId;
                        assignmentIdTeacher =
                            teacherAssignmentCompleted.deployment.assignment.assignmentId;
                        assignmentProgressIdTeacher =
                            teacherAssignmentCompleted.assignmentProgressId;
                    } else {
                        //      Has Progress / No Completed
                        if (teacherId === progressSubmittedBy) {
                            submittedBy = 'teacher';
                        } else if (currentCoach === progressSubmittedBy) {
                            submittedBy = 'coach';
                        } else {
                            isExpired = true;
                            submittedBy = null;
                        }
                        const startedDate = this.getDate(teacherFlowInProgress.startedAt);
                        expirationDateTeacher = add(startedDate, 30);
                        deploymentIdTeacher = teacherAssignmentInProgress.deployment.deploymentId;
                        assignmentIdTeacher =
                            teacherAssignmentInProgress.deployment.assignment.assignmentId;
                        assignmentProgressIdTeacher =
                            teacherAssignmentInProgress.assignmentProgressId;
                    }
                } else if (!teacherAssignmentInProgress) {
                    if (teacherAssignmentCompleted) {
                        const completedSubmittedBy = +teacherAssignmentCompleted.devTags
                            .SubmittedBy;

                        if (teacherId === completedSubmittedBy) {
                            submittedBy = 'teacher';
                        } else if (currentCoach === completedSubmittedBy) {
                            submittedBy = 'coach';
                        } else {
                            isExpired = true;
                            submittedBy = null;
                        }

                        const startedDate = this.getDate(teacherFlowCompleted.startedAt);
                        completedDateTeacher = this.getDate(teacherFlowCompleted.completedAt);
                        expirationDateTeacher = add(startedDate, 30);

                        deploymentIdTeacher = teacherAssignmentCompleted.deployment.deploymentId;
                        assignmentIdTeacher =
                            teacherAssignmentCompleted.deployment.assignment.assignmentId;
                        assignmentProgressIdTeacher =
                            teacherAssignmentCompleted.assignmentProgressId;

                        // submittedBy = submittedByTeacher ? 'teacher' : 'coach';
                    } else {
                        // NO Completed - NO Data at all
                        teacherIsNew = true;
                    }
                }

                return {
                    value: +item.pawn.pawnId,
                    name: item.user.fullName,
                    pawn: {
                        pawnId: +item.pawn.pawnId,
                        pawnHash: item.pawn.pawnHash,
                        respondentHash: item.pawn.respondentHash,
                    },
                    label: item.user.fullName,
                    role: item.pawn.role,
                    surveyInProgress: teacherAssignmentInProgress ? true : false,
                    expirationDate: format(expirationDateTeacher, 'MM/DD/YYYY'),
                    isExpired: isExpired ? true : isAfter(new Date(), expirationDateTeacher),
                    deploymentId: deploymentIdTeacher,
                    assignmentId: assignmentIdTeacher,
                    assignmentProgressId: assignmentProgressIdTeacher,
                    isNew: teacherIsNew ? teacherIsNew : false,
                    completedDate: completedDateTeacher,
                    submittedBy: submittedBy ? submittedBy : null,
                    coach: {
                        name: data.coach.user.fullName,
                        id: +data.coach.pawn.pawnId,
                    },
                };
            });

            return coach.concat(teacherList);
        }
    };

    processGoals = metrics => {
        const newMetrics = _omit(metrics, '__typename');
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        rows.forEach(item => {
            if (metrics[item.goalTag] === '1') {
                newMetrics[item.goalTag] = true;
            } else {
                newMetrics[item.goalTag] = false;
            }
        });

        return newMetrics;
    };

    changeSelection = async (pawnId, hashArr, label) => {
        this.setState({ loading: true });
        const newSelection = this.state.userList.find(item => item.label === label);
        const res = await this.getUserList();
        const userList = this.processUserList(res.data.roles);
        const metrics = await this.getMetrics(
            +pawnId,
            newSelection.deploymentId,
            +newSelection.assignmentId
        );
        const stateKeys = this.processQueryData(metrics);

        this.setState({
            loading: false,
            userList,
            feedbackMetrics: metrics,
            selectedPerson: newSelection,
            ...stateKeys,
        });
    };

    determineSection = row => {
        let section;
        row = +row;
        if (row <= 4) {
            section = 'CARES';
        } else if (row >= 5) {
            section = 'Positive Behavior Supports & Classroom Climate';
        }

        return section;
    };

    saveShareChange = async shareReport => {
        const {
            selectedPerson: {
                pawn: { pawnId, pawnHash },
                deploymentId,
                assignmentProgressId,
                assignmentId,
            },
        } = this.state;

        const shareData = {
            sourceId: assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ share_report: this.state.shareReport }),
            pawnId,
            pawnHash,
            assignmentProgressId,
            responseSet: +deploymentId,
        };

        const response = await Axios.post(
            Boilerplate.route('metrics.api.v1.data.bulkUpdate'),
            shareData
        );
        if (response) {
            this.activityLog.loadData();
            if (response.data.status === 'success') {
                const message = shareReport
                    ? 'Feedback Report has been shared.'
                    : 'Feedback Report is no longer shared.';
                Notification(message);
            } else {
                Notification({
                    message:
                        'Unable to share Feedback Report. Please contact your system administrator.',
                    type: 'danger',
                });
            }
        }
    };

    reloadUserList = async pawnId => {
        // Makes query to get updated progress from GRAPHQL
        // repopulate user list  or change state object?
        const res = await this.getUserList();
        const userList = this.processUserList(res.data.roles);
        const currentUser = userList.find(item => +item.value === +pawnId);

        this.setState({
            selectedPerson: currentUser,
            userList,
        });
    };

    reloadMetrics = async selectedPerson => {
        const metrics = await this.getMetrics(
            selectedPerson.pawnId,
            selectedPerson.deploymentId,
            selectedPerson.assignmentId
        );
        const stateKeys = this.processQueryData(metrics);

        this.setState({
            ...stateKeys,
            feedbackMetrics: metrics,
        });
    };

    getUserList = async () => {
        const {
            content: {
                checkUpTable: { userListQuery },
            },
        } = this.props;

        const res = await apollo.query({
            query: gql`
                ${userListQuery}
            `,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: Fortress.user.acting.id,
                assignmentHandleCoach: 'feedbackSurveyCoach',
                assignmentHandleTeacher: 'feedbackSurveyTeacher',
            },
        });

        return res;
    };

    getMetrics = async (pawnId, deploymentId, assignmentId) => {
        const {
            content: {
                checkUpTable: { metricsQuery },
            },
        } = this.props;
        const metrics = await apollo.query({
            query: gql`
                ${metricsQuery}
            `,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: pawnId ? +pawnId : null,
                deploymentId: deploymentId ? +deploymentId : null,
                assignmentId: assignmentId ? +assignmentId : null,
            },
        });

        return metrics;
    };

    getGoalMetrics = goals => {
        let newGoals = {};
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        rows.forEach(item => {
            newGoals[item.goalMetric] = goals[item.goalTag];
        });
        return newGoals;
    };

    getNotesFromProps = () => {
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const notes = rows.map(item => ({
            label: item.label,
            notesTag: item.notesTag,
        }));
        return notes;
    };

    getGoalsFromProps = () => {
        let goals = {};
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        for (const row of rows) {
            goals[row.goalTag] = false;
        }

        return goals;
    };

    getDate = date => {
        const dateTimeArr = date.split(' ');
        const dateArr = dateTimeArr[0].split('-');
        const startedDate = new Date(dateArr[0], +dateArr[1] - 1, dateArr[2]);

        return format(startedDate, 'MM/DD/YYYY');
    };

    getTabList = () => {
        const {
            selectedPerson,
            feedbackMetrics,
            userList,
            goals,
            initialGoals,
            goalChanges,
        } = this.state;

        const tabList = [
            {
                name: 'checkupFeedback',
                title: 'Check-Up Feedback',
                content: (
                    <FeedbackTable
                        reloadUserList={this.reloadUserList}
                        reloadMetrics={this.reloadMetrics}
                        handleGoalChange={this.handleGoalChange}
                        handleGoalSubmit={this.handleGoalSubmit}
                        content={this.props.content}
                        metrics={feedbackMetrics}
                        selectedPerson={selectedPerson}
                        coach={userList[0]}
                        goals={{
                            goals,
                            initialGoals,
                            goalChanges,
                        }}
                    />
                ),
            },
            {
                name: 'planningForm',
                title: 'Goal Setting Plan',
                content: (
                    <div style={{ marginBottom: '50px' }}>
                        <InterventionPlanning
                            loadData={this.reloadActivityLog}
                            timestamps={this.getTimeStamps()}
                            selectedPerson={selectedPerson}
                            goals={goals}
                            noDevTags={this.noDevTags()}
                        />
                    </div>
                ),
            },
        ];
        return tabList;
    };

    reloadActivityLog = async () => {
        await this.activityLog.loadData();
    };

    getDevTags = () => {
        const { feedbackMetrics } = this.state;
        if (feedbackMetrics) {
            return feedbackMetrics.data.roles.anyRole.assignmentProgress.devTags;
        }
        return {};
    };

    getTimeStamps = () => {
        const { feedbackMetrics } = this.state;
        if (feedbackMetrics) {
            return feedbackMetrics.data.roles.anyRole.assignmentProgress.variableTimestamps;
        }
        return {};
    };

    handleShareChange = () => {
        this.setState(
            state => ({ shareReport: !state.shareReport }),
            () => {
                this.saveShareChange(this.state.shareReport);
            }
        );
    };

    handleGoalSubmit = async () => {
        const {
            goals,
            selectedPerson: {
                pawn: { pawnId, pawnHash },
                deploymentId,
                assignmentProgressId,
                assignmentId,
            },
        } = this.state;

        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const goalMetrics = this.getGoalMetrics(goals);

        const goalsData = {
            sourceId: assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify(goalMetrics),
            pawnId,
            pawnHash,
            assignmentProgressId,
            responseSet: deploymentId,
        };

        const response = await Axios.post(
            Boilerplate.route('metrics.api.v1.data.bulkUpdate'),
            goalsData
        );

        if (response.data.data.status === 'success') {
            Notification('Goals were updated');

            let initialSet = this.state.initialGoals;

            const metrics = await this.getMetrics(+pawnId, +deploymentId, +assignmentId);

            const stateKeys = this.processQueryData(metrics);
            this.setState(
                {
                    ...stateKeys,
                    feedbackMetrics: metrics,
                },
                () => {
                    this.handleButtonChange();
                }
            );

            // save an event for each goal that was just added
            for (const key of Object.keys(goals)) {
                const rowIndex = rows.find(item => item.goalTag === key).order;
                if (goals[key] && !initialSet[key]) {
                    addEvent(
                        {
                            eventType: 'goalSelected',
                            targetId: pawnId,
                            targetType: 'pawn',
                            metadata: JSON.stringify({
                                goal: key,
                                deploymentId,
                                assignmentProgressId,
                                row: rowIndex,
                            }),
                        },
                        { saveImmediately: true }
                    );
                    this.activityLog.loadData();
                }

                // check if the goal was just removed
                if (!goals[key] && initialSet[key]) {
                    // if so, then add an event
                    addEvent(
                        {
                            eventType: 'goalRemoved',
                            targetId: pawnId,
                            targetType: 'pawn',
                            metadata: JSON.stringify({
                                goal: key,
                                deploymentId,
                                assignmentProgressId,
                                row: rowIndex,
                            }),
                        },
                        { saveImmediately: true }
                    );
                    this.activityLog.loadData();
                }
            }
        } else {
            Notification({
                message: 'Problem updating goals',
                type: 'danger',
                data: response && response.data.data && response.data.data.errors,
                duration: 0,
            });
        }
    };

    handleGoalChange = r => {
        const { goals } = this.state;

        this.setState(
            {
                goals: {
                    ...goals,
                    [r.goalTag]: !goals[r.goalTag],
                },
            },
            () => {
                this.handleButtonChange();
            }
        );
    };

    handleButtonChange = () => {
        const { initialGoals, goals } = this.state;

        if (!_isEqual(initialGoals, goals)) {
            const initialArr = Object.keys(initialGoals).map(item => {
                return {
                    value: initialGoals[item],
                    key: item,
                };
            });
            const currentArr = Object.keys(goals).map(item => {
                return {
                    value: goals[item],
                    key: item,
                };
            });

            const filteredArr = currentArr.filter((item, i) => !_isEqual(item, initialArr[i]));

            this.setState({
                goalChanges: filteredArr.length,
            });
        } else {
            this.setState({
                goalChanges: 0,
            });
        }
    };

    handleGoalChange = r => {
        const { goals } = this.state;

        this.setState(
            {
                goals: {
                    ...goals,
                    [r.goalTag]: !goals[r.goalTag],
                },
            },
            () => {
                this.handleButtonChange();
            }
        );
    };

    handleUnload = event => {
        if (this.state.goalChanges > 0) {
            const message = 'You have unsaved changes';
            event.returnValue = message;
            return message;
        }
    };

    onSelectionChange = event => {
        const pawnId = +event.target.value;
        const pawnHash = event.target.selectedOptions[0].getAttribute('name');
        const hashArr = pawnHash.split('--');
        const label = event.target.selectedOptions[0].label;
        if (this.state.goalChanges > 0) {
            Confirm({
                message: `You have unsaved changes?`,
                description: `Switching tabs will cause all unsaved changes to Current Goals to be lost.`,
                confirmLabel: 'Continue',
                abortLabel: 'Cancel',
            }).then(() => {
                this.changeSelection(pawnId, hashArr, label);
                this.setState({ goalChanges: 0 });
            });
        } else {
            this.changeSelection(pawnId, hashArr, label);
        }
    };

    render() {
        // const tabList = ;
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        return (
            <Loader loading={this.state.loading} removeChildren>
                <div className={style.pageWrapper}>
                    <Goals
                        determineSection={this.determineSection}
                        user={this.state.selectedPerson}
                        goals={this.state.initialGoals}
                        rows={rows}
                        timestamps={this.getTimeStamps()}
                        devTags={this.getDevTags()}
                        noDevTags={this.noDevTags()}
                    />
                    <div className={style.actionRow}>
                        {this.canBeShared() && !this.noDevTags() && (
                            <div className={style.shareToggle}>
                                <strong>Share with Teacher:</strong>
                                <CccisdToggle
                                    name="shareToggle"
                                    value={this.state.shareReport}
                                    onChange={this.handleShareChange}
                                />
                            </div>
                        )}
                        {this.state.selectedPerson && this.state.userList && (
                            <div className={style.selectRow}>
                                <strong>Teacher:</strong>
                                <Select
                                    options={this.state.userList}
                                    onSelectionChange={this.onSelectionChange}
                                    value={
                                        this.state.selectedPerson
                                            ? +this.state.selectedPerson.pawn.pawnId
                                            : 0
                                    }
                                />
                            </div>
                        )}
                    </div>
                    <div className={style.tabWrapper}>
                        <Tabs tabList={this.getTabList()} saveInHash />
                    </div>
                    <div className={style.activityLogBox}>
                        <ActivityLog
                            ref={element => {
                                this.activityLog = element;
                            }}
                            rows={rows}
                            devTags={this.getDevTags()}
                            userList={this.state.userList}
                            content={this.props.content}
                            selectedPerson={this.state.selectedPerson}
                            getMetrics={this.getMetrics}
                            getUserList={this.getUserList}
                            processQueryData={this.processQueryData}
                            determineSection={this.determineSection}
                        />
                    </div>
                </div>
            </Loader>
        );
    }
}
