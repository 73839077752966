import React from 'react';

import PropTypes from 'prop-types';
import style from './style.css';

const Select = props => {
    return (
        <select
            className={`form-control ${style.selectBox}`}
            onChange={props.onSelectionChange}
            value={props.value}
        >
            {props.options.map(option => (
                <option key={option.value} value={option.value} name={option.name}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

Select.propTypes = {
    options: PropTypes.array,
    onSelectionChange: PropTypes.func,
    value: PropTypes.number,
};

export default Select;
