import React from 'react';
import PropTypes from 'prop-types';

import Table from 'cccisd-graphql-table';

import logQuery from './acitivtyLog.graphql';
import FeedbackTable from '../FeedbackTable';
import style from './style.css';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import format from 'date-fns/format';

const Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    static propTypes = {
        rows: PropTypes.array,
        selectedPerson: PropTypes.object,
        userList: PropTypes.array,
        content: PropTypes.object,
        getMetrics: PropTypes.func,
        devTags: PropTypes.object,
        processQueryData: PropTypes.func,
        determineSection: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.feedbackTable = React.createRef();
        this.state = {
            noDevTags: true,
        };
    }

    componentDidUpdate(prevProps) {
        if (!_isEqual(prevProps, this.props)) {
            this.loadData();
        }
    }

    loadData = async () => {
        await this.table.loadData();
    };

    handleClick = async row => {
        const targetId = row.targetId;
        const deploymentId = JSON.parse(row.metadata).deploymentId;
        const { userList, getMetrics, processQueryData, selectedPerson } = this.props;
        const user = userList ? userList.find(item => item.value === +targetId) : selectedPerson;

        const metrics = await getMetrics(+user.value, +deploymentId, +user.assignmentId);
        const stateKeys = processQueryData(metrics);

        this.setState(
            {
                selectedPerson: user,
                feedbackMetrics: metrics,
                noDevTags: metrics ? false : true,
                ...stateKeys,
            },
            () => {
                const feedbackTable = this.feedbackTable.current;
                feedbackTable.downloadPdf();
            }
        );
    };

    render() {
        const { selectedPerson } = this.props;

        const coachColumns = [
            {
                name: 'eventDate',
                label: 'Last Updated',
                className: 'text-center',
                width: '10%',
                render: ({ value, row }) => {
                    if (value) {
                        const date = format(value, 'MM/DD/YYYY');
                        return <span>{date}</span>;
                    }
                },
            },
            {
                name: 'eventType',
                label: 'Item Created',
                render: ({ value, row }) => {
                    const role = selectedPerson.role === 'teacher' ? 'coach' : 'teacher';
                    const { rows, determineSection, devTags } = this.props;
                    if (value === 'feedbackSurveyCompleted') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>Feedback Survey Completed</span>
                        );
                    }
                    if (value === 'feedbackSurveyStarted') {
                        return <span style={{ fontWeight: 'bold' }}>Feedback Survey Started</span>;
                    }

                    const data = JSON.parse(row.metadata);

                    if (value === 'unlinkCoach') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>
                                Unlinked with {role}: {data.coach.name}
                            </span>
                        );
                    }
                    if (value === 'linkCoach') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>
                                Linked with with {role}: {data.coach.name}
                            </span>
                        );
                    }
                    if (value === 'linkAdmin') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>
                                Linked with admin: {data.admin.name}
                            </span>
                        );
                    }
                    if (value === 'unlinkAdmin') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>
                                Unlinked with admin: {data.admin.name}
                            </span>
                        );
                    }
                    if (value === 'planningSurveyStarted') {
                        return <span style={{ fontWeight: 'bold' }}>Planning Survey Started</span>;
                    }
                    if (value === 'planningSurveyCompleted') {
                        return (
                            <span style={{ fontWeight: 'bold' }}>Planning Survey Completed</span>
                        );
                    }

                    const currentRow = rows.find(item => item.goalTag === data.goal);

                    let label = '';
                    if (currentRow.isOther) {
                        const otherLabel = devTags[currentRow.labelTag];

                        label = (
                            <span>
                                <span>Other:</span>
                                <span style={{ textDecoration: 'underline' }}> {otherLabel} </span>
                            </span>
                        );
                    } else {
                        label = currentRow.label;
                    }

                    return (
                        <div>
                            <span style={{ fontWeight: 'bold' }}>
                                {value === 'goalRemoved' ? (
                                    <span style={{ minHeight: '40px' }}>Goal Removed: </span>
                                ) : (
                                    <span className={style.minus}>Goal Added: </span>
                                )}
                            </span>{' '}
                            <span> {label} | </span>
                            <span style={{ fontStyle: 'italic' }}>
                                {determineSection(data.row)}
                            </span>
                        </div>
                    );
                },
            },
            {
                name: 'pawn.pawn.roleName',
                label: 'Updated By',
                className: 'text-center',
                width: '10%',
            },
            {
                name: '',
                label: 'Actions',
                className: 'text-center',
                width: '25%',
                render: ({ value, row }) => {
                    const eventType = _get(row, 'eventType');
                    return (
                        <>
                            {eventType === 'feedbackSurveyCompleted' ? (
                                <button
                                    type="button"
                                    className={`btn ${style.actionButton}`}
                                    onClick={() => {
                                        this.handleClick(row);
                                    }}
                                >
                                    View Feedback Form
                                </button>
                            ) : (
                                ''
                            )}
                        </>
                    );
                },
            },
        ];

        return (
            <div>
                {selectedPerson && (
                    <>
                        <h3 className={style.title}>Activity Log</h3>
                        <Table
                            ref={element => {
                                this.table = element;
                            }}
                            perPage={15}
                            key={selectedPerson.pawnId}
                            query={logQuery}
                            rowKey="eventId"
                            graphqlVariables={{
                                teacherId: +selectedPerson.value,
                                coachId:
                                    selectedPerson.role === 'teacher'
                                        ? +selectedPerson.coach.id
                                        : +Fortress.user.acting.id,
                            }}
                            columns={
                                selectedPerson.role === 'coach'
                                    ? coachColumns
                                    : [
                                          ...coachColumns,
                                          {
                                              name: '',
                                              label: 'Shared',
                                              width: '10%',
                                              className: 'text-center',
                                              render: ({ value, row }) => {
                                                  if (row.eventType) {
                                                      const teacherAction =
                                                          _get(row, 'pawn.pawn.roleName') ===
                                                          'Teacher';

                                                      const progressList = _get(
                                                          row,
                                                          'pawn.descendantRoles.teacher.assignmentProgressList'
                                                      );
                                                      const deploymentId = JSON.parse(row.metadata)
                                                          .deploymentId;
                                                      const match = progressList.find(
                                                          item =>
                                                              +item.deployment.deploymentId ===
                                                              +deploymentId
                                                      );

                                                      if (match) {
                                                          if (match.devTags.ShareReport === '1') {
                                                              return <span>Yes</span>;
                                                          }
                                                      } else if (teacherAction) {
                                                          return <span>Yes</span>;
                                                      }
                                                  }
                                                  return null;
                                              },
                                          },
                                      ]
                            }
                        />
                        {!this.state.noDevTags && (
                            <div style={{ display: 'none' }}>
                                <FeedbackTable
                                    ref={this.feedbackTable}
                                    content={this.props.content}
                                    metrics={this.state.feedbackMetrics}
                                    selectedPerson={this.state.selectedPerson}
                                    coach={this.props.userList[0]}
                                    goals={{
                                        goals: this.state.goals,
                                        initialGoals: this.state.initialGoals,
                                        goalChanges: this.state.goalChanges,
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    }
}
