import React from 'react';
import PropTypes from 'prop-types';

import { Report, PrintOnly, BrowserOnly, Page } from 'cccisd-laravel-report';
import CccisdToggle from 'cccisd-toggle';
import Axios from 'cccisd-axios';
import Tooltip from 'cccisd-tooltip';
import Notification from 'cccisd-notification';
import { addEvent } from 'cccisd-laravel-eventlog';
import Star from 'cccisd-icons/star-full';
import Plus from 'cccisd-icons/plus2';
import Check from 'cccisd-icons/checkmark-circle';
import CheckMark from 'cccisd-icons/checkmark';
import Info from 'cccisd-icons/info2';
import ArrowSVG from './NeedsAttention_Strength-Arrow.svg';
import ViewLinks from 'js/components/ViewLinks';
import StartSurvey from '../StartSurvey';
import EditSurvey from '../EditSurvey';
import RowDrop from '../RowDrop';
import Notes from '../Notes';
import Loader from 'cccisd-loader';
import style from './style.css';

import _isEqual from 'lodash/isEqual';
import _last from 'lodash/last';
import format from 'date-fns/format';
const Boilerplate = window.cccisd.boilerplate;

// Unload Check for Goals
// userList Update*
// metrics Update*
// Goals*
// Notes*ƒ
// Messages*
// Completed By *
// Share? *

export default class FeedbackTable extends React.Component {
    static propTypes = {
        content: PropTypes.object,
        metrics: PropTypes.object,
        selectedPerson: PropTypes.object,
        coach: PropTypes.object,
        goals: PropTypes.object,
        reloadUserList: PropTypes.func,
        reloadMetrics: PropTypes.func,
        handleGoalChange: PropTypes.func,
        handleGoalSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const stateKeys = props.metrics ? this.processQueryData(props.metrics) : {};

        this.state = {
            ...stateKeys,
            goals: props.goals.goals,
            initialGoals: props.goals.initialGoals,
            goalChanges: props.goals.goalChanges,
            loading: true,
        };
    }

    componentDidMount = async () => {
        const messages = await Axios.get(Boilerplate.route('app.get.data'));

        let formattedMessages;

        if (messages.data.success) {
            formattedMessages = this.getMessages(messages);
        }

        this.setState({
            dropDownContent: formattedMessages.length > 0 ? formattedMessages : null,
            loading: false,
        });
    };

    componentDidUpdate = prevProps => {
        if (!_isEqual(prevProps, this.props)) {
            const stateKeys = this.props.metrics ? this.processQueryData(this.props.metrics) : {};
            this.setState({
                ...stateKeys,
                goals: this.props.goals.goals,
                initialGoals: this.props.goals.initialGoals,
                goalChanges: this.props.goals.goalChanges,
            });
        }
    };

    processOther = (array, section) => {
        if (array.length > 0) {
            let newArray = [...array];
            let last = _last(newArray);

            newArray[newArray.length - 1] = { [section + 'Other']: last.other };

            return newArray;
        }
        return [];
    };

    processQueryData = metrics => {
        if (metrics) {
            const eventList = metrics.data.events.eventList;

            const assignmentProgress = metrics.data.roles.anyRole.assignmentProgress;
            const flowProgress = metrics.data.roles.anyRole.flowProgress;
            const devTags = assignmentProgress.devTags;
            const reportShared = devTags.ShareReport === '1' ? true : false;
            const notes = assignmentProgress.notes;
            const notesMetrics = metrics.data.metrics.variableList;
            const completedDate = flowProgress.completedAt
                ? format(flowProgress.completedAt, 'MM/DD/YYYY')
                : '';
            const devTagArr = Object.keys(devTags).map(item => {
                return {
                    value: devTags[item],
                    key: item,
                };
            });

            const noDevTags = devTagArr
                .filter(item => item.value !== 'MetricsDevTags')
                .every(item => item.value === null || item.value === '');

            const dataObj = {
                devTags,
                shareReport: reportShared,
                notes,
                notesMetrics,
                completedDate,
                noDevTags,
                eventList,
            };
            return dataObj;
        }
    };

    closeExpandables = () => {
        this.setState({
            row: null,
            col: null,
        });
    };

    downloadPdf = () => {
        this.setState({
            row: null,
            col: null,
        });

        setTimeout(() => {
            this.report.download();
            Notification({
                message: 'Please wait a moment while your PDF is prepared',
                type: 'info',
                duration: 5000,
            });
        }, 1000);
    };

    assignCell = cell => {
        cell.row = +cell.row;
        cell.col = +cell.col;

        if (this.state.row === cell.row && this.state.col === cell.col) {
            this.setState({
                row: null,
                col: null,
            });
        } else {
            this.setState({
                row: cell.row,
                col: cell.col,
            });
        }
    };

    handleCellClick = event => {
        let clickedCell;

        // Determine Cell
        switch (event.target.nodeName) {
            case 'path':
                clickedCell = event.target.parentElement.parentElement.parentElement.parentElement;
                break;
            case 'svg':
                clickedCell = event.target.parentElement.parentElement;
                break;
            case 'TD':
                clickedCell = event.target;
                break;
            case 'SPAN':
                clickedCell = event.target.parentElement;
                break;
            case 'g':
                clickedCell = event.target.parentElement.parentElement.parentElement;
                break;
            default:
                break;
        }

        const classArr = clickedCell.className.split(' ');
        const row = classArr[0].slice(3);
        const col = classArr[1].slice(3);
        const cell = { row, col };
        this.assignCell(cell);
    };

    handleNotesSubmit = async (value, label, devTag) => {
        const note = { value: value.notes, label };
        const {
            selectedPerson: {
                pawn: { pawnId, pawnHash },
                deploymentId,
                assignmentProgressId,
                assignmentId,
            },
        } = this.props;
        const { notesMetrics } = this.state;

        const foundMetric = notesMetrics.filter(item => item.tag.name === devTag);

        if (foundMetric.length === 0) {
            Notification({
                message: 'Problem updating notes',
                type: 'danger',
                data: `Notes metric not found for ${label} with tag ${devTag}`,
                duration: 0,
            });
            return;
        }
        const key = foundMetric[0].key.entityId + '_answer';

        const notesData = {
            sourceId: assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ [key]: note.value }),
            pawnId,
            pawnHash,
            assignmentProgressId,
            responseSet: deploymentId,
        };

        const response = await Axios.post(
            Boilerplate.route('metrics.api.v1.data.bulkUpdate'),
            notesData
        );

        if (response.data.data.status === 'success') {
            Notification({
                message: `Notes were updated`,
                type: 'success',
                data: label,
                duration: 8000,
            });
            this.setState({
                notes: {
                    ...this.state.notes,
                    [devTag]: value.notes,
                },
            });

            return true;
        }

        Notification({
            message: 'Problem updating notes',
            type: 'danger',
            data: response && response.data.data && response.data.data.errors,
            duration: 0,
        });
    };

    setLoading = () => {
        this.setState({ loading: true });
    };

    unsetLoading = () => {
        this.setState({ loading: false });
    };

    onEditComplete = async (pawnId, pawnHash, deploymentId, assignmentId) => {
        const response = await Axios.post(Boilerplate.route('checkup.feedback.scoring'), {
            deploymentId,
            pawnHash,
            pawnId: +pawnId,
            assignmentId,
        });

        if (response.data.success === true) {
            // Get new userList
            this.setLoading();
            await this.props.reloadUserList(+pawnId);
            // new Metrics
            await this.props.reloadMetrics({ pawnId, deploymentId, assignmentId });
            this.unsetLoading();
        }
    };

    onNewClose = async () => {
        // Makes query to get updated progress from GRAPHQL
        const {
            selectedPerson: {
                pawn: { pawnId },
            },
        } = this.props;
        this.setLoading();
        await this.props.reloadUserList(pawnId);
        this.unsetLoading();
    };

    onNewComplete = async (pawnId, pawnHash, deploymentId, assignmentId) => {
        const response = await Axios.post(Boilerplate.route('checkup.feedback.scoring'), {
            deploymentId,
            pawnHash,
            pawnId: +pawnId,
        });

        addEvent(
            {
                eventType: 'feedbackSurveyCompleted',
                targetId: +pawnId,
                targetType: 'pawn',
                metadata: JSON.stringify({ deploymentId }),
            },
            { saveImmediately: true }
        );

        if (response.data.success === true) {
            // Get new userList
            this.setLoading();
            await this.props.reloadUserList(+pawnId);
            // new Metrics
            await this.props.reloadMetrics({ pawnId, deploymentId, assignmentId });
            this.unsetLoading();
        } else {
            Notification({
                message: 'Problem saving survey data',
                type: 'danger',
                duration: 0,
            });
        }

        return response;
    };

    getMessages = msg => {
        const data = msg.data.data;
        const CARESData = data.CARES ? JSON.parse(data.CARES.data) : [];
        const PBSCCData = data.PBSCC ? JSON.parse(data.PBSCC.data) : [];

        const PBSCCArr = this.processOther(PBSCCData, 'PBSCC');

        const combinedArr = CARESData.concat(PBSCCArr);
        const finalArr = combinedArr.map(item => {
            const key = Object.keys(item)[0];

            const messages = item[key].map((array, i) => {
                return {
                    column: i + 1,
                    bullets: array,
                };
            });

            return {
                handle: key,
                messages,
            };
        });

        return finalArr;
    };

    getDefaultStyle = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstCol;
                break;
            case 1:
                newStyle = style.secondCol;
                break;
            case 2:
                newStyle = style.thirdCol;
                break;
            case 3:
                newStyle = style.fourthCol;
                break;
            case 4:
                newStyle = style.fifthCol;
                break;
            case 5:
                newStyle = style.sixthCol;
                break;
            case 6:
                newStyle = style.seventhCol;
                break;
            case 7:
                newStyle = style.eighthCol;
                break;
            case 8:
                newStyle = style.ninthCol;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getSectionDivide = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstColDivide;
                break;
            case 1:
                newStyle = style.secondColDivide;
                break;
            case 2:
                newStyle = style.thirdColDivide;
                break;
            case 3:
                newStyle = style.fourthColDivide;
                break;
            case 4:
                newStyle = style.fifthColDivide;
                break;
            case 5:
                newStyle = style.sixthColDivide;
                break;
            case 6:
                newStyle = style.seventhColDivide;
                break;
            case 7:
                newStyle = style.eighthColDivide;
                break;
            case 8:
                newStyle = style.ninthColDivide;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getClickedStyle = col => {
        let newStyle;
        switch (col) {
            case 0:
                newStyle = style.firstCol;
                break;
            case 1:
                newStyle = style.secondCol;
                break;
            case 2:
                newStyle = style.thirdCol;
                break;
            case 3:
                newStyle = style.fourthColOpen;
                break;
            case 4:
                newStyle = style.fifthColOpen;
                break;
            case 5:
                newStyle = style.sixthColOpen;
                break;
            case 6:
                newStyle = style.seventhColOpen;
                break;
            case 7:
                newStyle = style.eighthColOpen;
                break;
            case 8:
                newStyle = style.ninthColOpen;
                break;
            default:
                break;
        }
        return newStyle;
    };

    getStyle = (row, col, sectionIndex) => {
        let finalStyle =
            sectionIndex === 0 ? this.getSectionDivide(col) : this.getDefaultStyle(col);
        if (col === this.state.col && row === this.state.row) {
            finalStyle = this.getClickedStyle(col);
        }
        return finalStyle;
    };

    getSection = row => {
        let section;
        row = +row;
        if (row <= 4) {
            section = 'CARES';
        } else if (row >= 5) {
            section = 'PBS & CC';
        }
        return section;
    };

    getCollapsed = section => {
        let colSpan;
        switch (section) {
            case 'CARES':
                colSpan = 5;
                break;
            case 'PBS & CC':
                colSpan = 8;
                break;
            default:
                break;
        }

        return colSpan;
    };

    getExpanded = clickedSection => {
        let colSpan;
        switch (clickedSection) {
            case 'CARES':
                colSpan = 6;
                break;
            case 'PBS & CC':
                colSpan = 9;
                break;
            default:
                break;
        }
        return colSpan;
    };

    getRowSpan = row => {
        let rowSpan;

        const section = this.getSection(row);

        rowSpan = this.getCollapsed(section);

        if (this.state.row || this.state.row === 0) {
            const clickedSection = this.getSection(this.state.row);

            if (section === clickedSection) {
                rowSpan = this.getExpanded(section);
            }
        }

        return rowSpan;
    };

    getOtherLabel = (devTags, labelTag) => {
        const labelData = devTags[labelTag];

        return `Other - ${labelData}`;
    };

    getTableData = (rows, state) => {
        const { devTags, notes, goals, dropDownContent } = state;

        const tableData = rows.map((r, i) => {
            const goalTrue = goals[r.goalTag];

            const check = (
                <span data-tip="Test" data-event="click focus">
                    <Check />
                </span>
            );

            const toggle = (
                <>
                    <BrowserOnly>
                        <div className={style.toggleWrapper}>
                            <CccisdToggle
                                name={r.label}
                                value={goals[r.goalTag]}
                                onChange={() => this.props.handleGoalChange(r)}
                            />
                        </div>
                    </BrowserOnly>
                    <PrintOnly>
                        {goalTrue && (
                            <div
                                style={{
                                    fontSize: '16pt',
                                    textAlign: 'center',
                                    color: '#458DCB',
                                    width: '100%',
                                }}
                            >
                                <CheckMark />
                            </div>
                        )}
                    </PrintOnly>
                </>
            );

            const noteContent = notes ? notes[r.notesTag] : '';

            const note = (
                <>
                    <BrowserOnly>
                        <span className={style.notesSpan}>
                            <Notes
                                index={i}
                                label={r.label}
                                onSubmit={this.handleNotesSubmit}
                                devTag={r.notesTag}
                                initialValue={noteContent ? noteContent : ''}
                            />
                        </span>
                    </BrowserOnly>
                    <PrintOnly>
                        {noteContent && (
                            <div
                                style={{
                                    fontSize: '16pt',
                                    textAlign: 'center',
                                    color: '#458DCB',
                                    width: '100%',
                                }}
                            >
                                <CheckMark />
                            </div>
                        )}
                    </PrintOnly>
                </>
            );

            let finalRow;
            let newRow;
            if (r.isOther) {
                const labelTag = r.labelTag;
                const displayVal = devTags ? devTags[labelTag] : '';
                const label = (
                    <span>
                        Other: <span className={style.otherLabel}>{displayVal}</span>
                    </span>
                );
                newRow = [toggle, note, label];
            } else {
                newRow = [toggle, note, r.label];
            }

            let devTagArr = devTags
                ? Object.keys(devTags).map(key => {
                      return { [key]: devTags[key] };
                  })
                : [];

            const match = devTagArr.find(item => r.devTag in item);
            const score = match && match[r.devTag] ? match[r.devTag] : null;

            let messageObj;
            if (dropDownContent) {
                messageObj = dropDownContent.find(item => item.handle === r.messageHandle);
            }

            const messages = messageObj ? messageObj.messages : r.messages;

            if (match) {
                const value = score ? Math.floor(+score) : null;
                switch (value) {
                    case 0:
                        finalRow = newRow.concat([check, '', '', '', '', '', messages]);
                        break;
                    case 1:
                        finalRow = newRow.concat(['', check, '', '', '', '', messages]);
                        break;
                    case 2:
                        finalRow = newRow.concat(['', '', check, '', '', '', messages]);
                        break;
                    case 3:
                        finalRow = newRow.concat(['', '', '', check, '', '', messages]);
                        break;
                    case 4:
                        finalRow = newRow.concat(['', '', '', '', check, '', messages]);
                        break;
                    case 5:
                        finalRow = newRow.concat(['', '', '', '', '', check, messages]);
                        break;
                    case null:
                        finalRow = newRow.concat(['', '', '', '', '', '', messages]);
                        break;
                    default:
                        break;
                }
            } else {
                finalRow = newRow.concat(['', '', '', '', '', '', messages]);
            }

            return finalRow;
        });
        return tableData;
    };

    getCell = (cell, row, col, sectionIndex) => {
        const newStyle = this.getStyle(row, col, sectionIndex);

        if (+col < 3) {
            return (
                <td className={`row${row} col${col} ${newStyle}`} key={`row${row}col${col}`}>
                    {cell}
                </td>
            );
        }
        return (
            <td
                className={`row${row} col${col} ${newStyle} ${style.colored}`}
                key={`row${row}col${col}`}
                onClick={this.handleCellClick}
            >
                {cell}
            </td>
        );
    };

    renderNotesPrintable = state => {
        const { notes } = state;

        const {
            selectedPerson,
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const notesArr = notes
            ? rows.map(item => {
                  return {
                      label: item.label,
                      value: notes[item.notesTag],
                      row: item.order,
                      labelTag: item.labelTag,
                  };
              })
            : [];
        if (selectedPerson) {
            const label =
                selectedPerson.role === 'coach' ? selectedPerson.name : selectedPerson.label;

            const coach = selectedPerson.coach ? selectedPerson.coach.name : 'N/A';

            const displayNotes = notesArr.filter(item => item.value !== null && item.value !== '');

            const CARESNotes = displayNotes.filter(item => this.getSection(item.row) === 'CARES');

            const PBSCCNotes = displayNotes.filter(
                item => this.getSection(item.row) === 'PBS & CC'
            );

            return (
                <PrintOnly>
                    <div className={style.printHeaderNotes}>
                        <div className={style.feedbackLabel}>
                            <h2 className={style.headerTitleNotes}>Check-Up Feedback Report</h2>
                            <span>
                                <span className={style.bold}>Teacher:</span> <span>{label}</span>
                                <span style={{ marginLeft: '.5em' }}>
                                    {this.renderCompletedBy(state)}
                                </span>{' '}
                            </span>
                        </div>
                        <div className={style.feedbackDateBox}>
                            <div style={{ marginBottom: '10px' }}>
                                <span className={style.bold}>Feedback Survey Completed: </span>
                                <span style={{ marginLeft: '.25em' }}>{state.completedDate}</span>
                            </div>
                            <div className={style.coachLabel}>
                                <span className={style.bold}>Coach: </span>

                                <span style={{ marginLeft: '.5em' }}>{coach}</span>
                            </div>
                        </div>
                    </div>
                    <div className={style.noteSection}>
                        <div className={`${style.bold}`}>
                            <span className={style.notesTriangle}>
                                <Star />
                            </span>
                            CARES Notes:
                        </div>
                        <div>
                            {CARESNotes.map((item, i) => {
                                let cellLabel = item.label;

                                if (item.label.includes('Other')) {
                                    cellLabel = this.getOtherLabel(state.devTags, item.labelTag);
                                }
                                return (
                                    <div key={i} className={style.notePrintBox}>
                                        <div>{cellLabel}:</div>
                                        <div className={style.noteText}>{item.value}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={style.noteSection}>
                        <div className={`${style.bold}`}>
                            <span className={style.noteSquare}>
                                <Plus />
                            </span>
                            Positive Behavior Supports & Classroom Climate Notes:
                        </div>
                        <div>
                            {PBSCCNotes.map((item, i) => {
                                let cellLabel = item.label;

                                if (item.label.includes('Other')) {
                                    cellLabel = this.getOtherLabel(state.devTags, item.labelTag);
                                }
                                return (
                                    <div key={i} className={style.notePrintBox}>
                                        <div>{cellLabel}:</div>
                                        <div className={style.noteText}>{item.value}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </PrintOnly>
            );
        }
    };

    // Side Tabs
    renderTableSection = (tableData, addIndex) => {
        const tableSection = tableData.map((item, index) => {
            return (
                <React.Fragment key={`R${index + addIndex}`}>
                    <tr
                        className={`row-${index + addIndex}`}
                        style={{ paddingBottom: '10px' }}
                        key={`row-${index + addIndex}`}
                    >
                        {item
                            .filter((cell, i) => i !== 9)
                            .map((cell, i) => {
                                const htmlCell = this.getCell(cell, index + addIndex, i, index);
                                if (index === 0 && i === 0 && addIndex === 0) {
                                    return (
                                        <React.Fragment key={i}>
                                            <td
                                                rowSpan={this.getRowSpan(index + addIndex)}
                                                className={style.sideTab}
                                            >
                                                <span className={style.structureText}>
                                                    CARES
                                                    <span className={style.triangle}>
                                                        <BrowserOnly>
                                                            <Star />
                                                        </BrowserOnly>
                                                    </span>
                                                </span>
                                            </td>
                                            {htmlCell}
                                        </React.Fragment>
                                    );
                                }
                                if (index === 0 && i === 0 && addIndex === 5) {
                                    return (
                                        <React.Fragment key={i}>
                                            <td
                                                rowSpan={this.getRowSpan(index + addIndex)}
                                                className={style.sideTab}
                                            >
                                                <BrowserOnly>
                                                    <span className={style.instructionText}>
                                                        Positive Behavior Supports & Classroom
                                                        Climate
                                                        <span className={style.square}>
                                                            <BrowserOnly>
                                                                <Plus />
                                                            </BrowserOnly>
                                                        </span>
                                                    </span>
                                                </BrowserOnly>
                                                <PrintOnly>
                                                    <span className={style.printText}>
                                                        PBS & CC
                                                        <span className={style.square}>
                                                            <BrowserOnly>
                                                                <Plus />
                                                            </BrowserOnly>
                                                        </span>
                                                    </span>
                                                </PrintOnly>
                                            </td>
                                            {htmlCell}
                                        </React.Fragment>
                                    );
                                }

                                return <React.Fragment key={i}>{htmlCell}</React.Fragment>;
                            })}
                    </tr>
                    {this.state.row === index + addIndex ? (
                        <RowDrop messages={item[9]} col={this.state.col} />
                    ) : null}
                </React.Fragment>
            );
        });

        return tableSection;
    };

    renderCompletedBy = state => {
        const { selectedPerson, metrics } = this.props;
        if (metrics) {
            const eventList = metrics.data.events.eventList;
            const match = eventList.find(item => {
                const metaData = JSON.parse(item.metadata);

                if (metaData.deploymentId === selectedPerson.deploymentId) {
                    return item;
                }
                return null;
            });

            if (match) {
                const completedBy = match.pawnId;
                let completedLabel;
                if (!selectedPerson.coach) {
                    completedLabel = selectedPerson.name;
                } else if (completedBy === selectedPerson.value) {
                    completedLabel = selectedPerson.label;
                } else {
                    completedLabel = match.pawn.user.fullName;
                }

                return (
                    <span>
                        <strong>Completed By: </strong>
                        <span style={{ fontWeight: 'normal' }}>{completedLabel}</span>
                    </span>
                );
            }
        }
    };

    renderPrintHeader = state => {
        const { selectedPerson } = this.props;

        if (selectedPerson) {
            const displayLabel = selectedPerson.coach ? selectedPerson.name : selectedPerson.label;

            return (
                <PrintOnly>
                    <div className={style.printHeader}>
                        <div className={style.feedbackLabel}>
                            <h2 className={style.headerTitle}>Check-Up Feedback Report</h2>
                            <span>
                                <span className={style.bold}>Teacher</span>{' '}
                                <span>{displayLabel}</span>
                                <span style={{ marginLeft: '.5em' }}>
                                    {this.renderCompletedBy(state)}
                                </span>{' '}
                            </span>
                        </div>
                        <div className={style.feedbackDateBox}>
                            <div style={{ marginBottom: '10px' }}>
                                <span className={style.bold}>Feedback Survey Completed: </span>
                                <span style={{ marginLeft: '.25em' }}>
                                    {selectedPerson.completedDate && selectedPerson.completedDate}
                                </span>
                            </div>
                            <div className={style.coachLabel}>
                                <span className={style.bold}>Coach: </span>

                                <span style={{ marginLeft: '.5em' }}>
                                    {selectedPerson.coach ? selectedPerson.coach.name : 'N/A'}
                                </span>
                            </div>
                        </div>
                    </div>
                </PrintOnly>
            );
        }
        return null;
    };

    renderCheckupTable = state => {
        const {
            content: {
                checkUpTable: { rows },
            },
        } = this.props;

        const tableData = this.getTableData(rows, state);

        // Separate Table Data
        const structureTable = tableData.slice(0, 5);
        const instructionTable = tableData.slice(5);

        return (
            <div className={style.tableWrapper}>
                <table className={`${style.table} table`}>
                    <thead className={style.tableHead}>
                        <tr className="row">
                            <th
                                style={{ border: 'none', backgroundColor: '#fff', width: '3.5%' }}
                            />
                            <th className={`text-center ${style.goalCol}`}>Goal</th>
                            <th className={`text-center ${style.notesCol}`}>Notes</th>
                            <th className={`text-center ${style.areaCol}`}>Area to Improve</th>
                            <th
                                colSpan="6"
                                style={{
                                    whiteSpace: 'nowrap',
                                    borderTopRightRadius: '15px',
                                    borderRight: 'none',
                                }}
                            >
                                <div className={style.arrowBox}>
                                    <span>Needs Attention</span>
                                    <img
                                        src={ArrowSVG}
                                        style={{ width: '45%' }}
                                        alt="Arrow goes here."
                                    />
                                    <span>Strength</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderTableSection(structureTable, 0)}
                        {this.renderTableSection(instructionTable, 5)}
                    </tbody>
                </table>
            </div>
        );
    };

    renderActionButtons = () => {
        return (
            <div className={style.buttonArea}>
                <button
                    type="button"
                    className={`btn btn-default ${style.printButton}`}
                    onClick={this.downloadPdf}
                >
                    Download PDF
                </button>
                <a
                    className={`btn btn-default ${style.printButton}`}
                    download
                    href="https://dm0gz550769cd.cloudfront.net/doublecheck/e8/e825c8cce29ff1ee003cb07d375db856.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View Blank Feedback Survey
                </a>
                {this.state.goalChanges > 0 ? (
                    <button
                        type="button"
                        className={`btn btn-success ${style.goalButtonActive}`}
                        style={{ color: '#fff' }}
                        onClick={this.props.handleGoalSubmit}
                    >
                        {`Update Goals (${this.state.goalChanges})`}
                    </button>
                ) : (
                    <button type="button" className={`btn  disabled ${style.goalButton}`}>
                        Update Goals
                    </button>
                )}
            </div>
        );
    };

    renderSurveyButton = () => {
        const currentUser = this.props.selectedPerson;

        if (currentUser) {
            if (currentUser.submittedBy) {
                // must be teacher survey
                if (currentUser.submittedBy === 'coach') {
                    // coach submitted
                    if (currentUser.surveyInProgress) {
                        return (
                            <>
                                {currentUser.expirationDate && (
                                    <div className={style.expirationDate}>
                                        {' '}
                                        <Tooltip
                                            title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                            size="medium"
                                            placement="bottom"
                                        >
                                            <span style={{ fontWeight: 'bold' }}>
                                                <sup>
                                                    <Info />
                                                </sup>{' '}
                                                Survey Editable Until:{' '}
                                            </span>
                                        </Tooltip>
                                        {currentUser.expirationDate}
                                    </div>
                                )}
                                <StartSurvey
                                    onNewClose={this.onNewClose}
                                    onComplete={this.onNewComplete}
                                    onEvalSuccess={this.onEvalSuccess}
                                    pawn={this.props.selectedPerson.pawn}
                                    progress={this.props.selectedPerson}
                                />{' '}
                            </>
                        );
                    }

                    if (currentUser.completedDate && !currentUser.isExpired) {
                        return (
                            <>
                                {currentUser.expirationDate && (
                                    <div className={style.expirationDate}>
                                        {' '}
                                        <Tooltip
                                            title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                            size="medium"
                                            placement="bottom"
                                        >
                                            <span style={{ fontWeight: 'bold' }}>
                                                <sup>
                                                    <Info />
                                                </sup>{' '}
                                                Survey Editable Until:{' '}
                                            </span>
                                        </Tooltip>
                                        {currentUser.expirationDate}
                                    </div>
                                )}
                                <EditSurvey
                                    onComplete={this.onEditComplete}
                                    pawn={this.props.selectedPerson.pawn}
                                    progress={this.props.selectedPerson}
                                />
                            </>
                        );
                    }
                    if (currentUser.isNew || currentUser.isExpired) {
                        return (
                            <StartSurvey
                                onNewClose={this.onNewClose}
                                onComplete={this.onNewComplete}
                                onEvalSuccess={this.onEvalSuccess}
                                pawn={this.props.selectedPerson.pawn}
                                progress={this.props.selectedPerson}
                            />
                        );
                    }
                }
                // submitted by teacher
                if (currentUser.surveyInProgress) {
                    if (!currentUser.isExpired) {
                        // disabled start
                        return (
                            <>
                                {currentUser.expirationDate && (
                                    <div className={style.expirationDate}>
                                        {' '}
                                        <Tooltip
                                            title="<p>This survey was started by a teacher. New surveys may be started 30 days after the date the previous survey is started.</p>"
                                            size="medium"
                                            placement="bottom"
                                        >
                                            <span style={{ fontWeight: 'bold' }}>
                                                <sup>
                                                    <Info />
                                                </sup>{' '}
                                                Survey started by teacher. Expires on:{' '}
                                            </span>
                                        </Tooltip>
                                        {currentUser.expirationDate}
                                    </div>
                                )}
                                <StartSurvey
                                    onNewClose={this.onNewClose}
                                    onComplete={this.onNewComplete}
                                    onEvalSuccess={this.onEvalSuccess}
                                    pawn={this.props.selectedPerson.pawn}
                                    progress={this.props.selectedPerson}
                                    disabled
                                />
                            </>
                        );
                    }
                    return (
                        <StartSurvey
                            onNewClose={this.onNewClose}
                            onComplete={this.onNewComplete}
                            onEvalSuccess={this.onEvalSuccess}
                            pawn={this.props.selectedPerson.pawn}
                            progress={this.props.selectedPerson}
                        />
                    );
                }
                // not in progress
                if (currentUser.completedDate && !currentUser.isExpired) {
                    return (
                        <>
                            {currentUser.expirationDate && (
                                <div className={style.expirationDate}>
                                    {' '}
                                    <Tooltip
                                        title="<p>This survey was started by a teacher. New surveys may be started 30 days after the date the previous survey is started.</p>"
                                        size="medium"
                                        placement="bottom"
                                    >
                                        <span style={{ fontWeight: 'bold' }}>
                                            <sup>
                                                <Info />
                                            </sup>{' '}
                                            Survey started by teacher. Expires on:{' '}
                                        </span>
                                    </Tooltip>
                                    {currentUser.expirationDate}
                                </div>
                            )}
                            <StartSurvey
                                onNewClose={this.onNewClose}
                                onComplete={this.onNewComplete}
                                onEvalSuccess={this.onEvalSuccess}
                                pawn={this.props.selectedPerson.pawn}
                                progress={this.props.selectedPerson}
                                disabled
                            />
                        </>
                    );
                }
                if (currentUser.isNew || currentUser.isExpired) {
                    return (
                        <StartSurvey
                            onNewClose={this.onNewClose}
                            onComplete={this.onNewComplete}
                            onEvalSuccess={this.onEvalSuccess}
                            pawn={this.props.selectedPerson.pawn}
                            progress={this.props.selectedPerson}
                        />
                    );
                }
            }
            // must be coach survey
            if (currentUser.surveyInProgress) {
                // in progress
                if (!currentUser.isExpired) {
                    // Resume Button
                    return (
                        <>
                            {currentUser.expirationDate && (
                                <div className={style.expirationDate}>
                                    {' '}
                                    <Tooltip
                                        title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                        size="medium"
                                        placement="bottom"
                                    >
                                        <span style={{ fontWeight: 'bold' }}>
                                            <sup>
                                                <Info />
                                            </sup>{' '}
                                            Survey Editable Until:{' '}
                                        </span>
                                    </Tooltip>
                                    {currentUser.expirationDate}
                                </div>
                            )}
                            <StartSurvey
                                onNewClose={this.onNewClose}
                                onComplete={this.onNewComplete}
                                onEvalSuccess={this.onEvalSuccess}
                                pawn={this.props.selectedPerson.pawn}
                                progress={this.props.selectedPerson}
                            />
                        </>
                    );
                }
                return (
                    <StartSurvey
                        onNewClose={this.onNewClose}
                        onEvalSuccess={this.onEvalSuccess}
                        onComplete={this.onNewComplete}
                        pawn={this.props.selectedPerson.pawn}
                        progress={this.props.selectedPerson}
                    />
                );
            }
            // not in progress
            if (currentUser.completedDate && !currentUser.isExpired) {
                return (
                    <>
                        {currentUser.expirationDate && (
                            <div className={style.expirationDate}>
                                {' '}
                                <Tooltip
                                    title="<p>You may edit the current survey until this date. Surveys are editable for 30 days from the date they are started.</p>"
                                    size="medium"
                                    placement="bottom"
                                >
                                    <span style={{ fontWeight: 'bold' }}>
                                        <sup>
                                            <Info />
                                        </sup>{' '}
                                        Survey Editable Until:{' '}
                                    </span>
                                </Tooltip>
                                {currentUser.expirationDate}
                            </div>
                        )}
                        <EditSurvey
                            onComplete={this.onEditComplete}
                            pawn={this.props.selectedPerson.pawn}
                            progress={this.props.selectedPerson}
                        />
                    </>
                );
            }

            if (currentUser.isNew || currentUser.isExpired) {
                // start button
                return (
                    <StartSurvey
                        onNewClose={this.onNewClose}
                        onEvalSuccess={this.onEvalSuccess}
                        onComplete={this.onNewComplete}
                        pawn={this.props.selectedPerson.pawn}
                        progress={this.props.selectedPerson}
                    />
                );
            }
        }
        return null;
    };

    renderRelationships = () => {
        const {
            selectedPerson: { parentPawns, pendingInvites, pawn },
        } = this.props;

        return (
            <div className={style.relations}>
                <ViewLinks
                    user={pawn}
                    pendingRequests={pendingInvites}
                    parentPawns={parentPawns}
                    reloadUserData={this.props.reloadUserList}
                />
            </div>
        );
    };

    render() {
        const { selectedPerson } = this.props;
        return (
            <Loader loading={this.state.loading}>
                <div className={style.buttonRow}>
                    {!this.state.noDevTags && this.state.completedDate && (
                        <>
                            <span>
                                <strong>Feedback Survey Completed:</strong>{' '}
                                <span className={style.completedDate}>
                                    {this.state.completedDate}
                                </span>
                                {!this.state.noDevTags && this.renderCompletedBy(this.state)}
                            </span>
                            {this.renderActionButtons()}
                        </>
                    )}
                </div>
                <div className={style.printWrapper}>
                    {!this.state.noDevTags && (
                        <Report
                            width="100%"
                            showPagination={false}
                            onRef={element => {
                                this.report = element;
                            }}
                            downloadFilename={`Check-UpFeedbackReport_${
                                selectedPerson ? selectedPerson.name : ''
                            }_${this.state.completedDate}`}
                        >
                            <Page>
                                {this.renderPrintHeader(this.state)}
                                {this.renderCheckupTable(this.state)}
                            </Page>
                            <Page>{this.renderNotesPrintable(this.state)}</Page>
                        </Report>
                    )}
                    {selectedPerson.role === 'coach' && this.renderRelationships()}
                    <hr />
                    <div className={style.bottomBar}>
                        <div />
                        <div className={style.buttonContainer}>
                            {this.renderSurveyButton()}
                            {this.state.noDevTags && (
                                <a
                                    className={`btn btn-default ${style.printButton}`}
                                    download
                                    href="https://dm0gz550769cd.cloudfront.net/doublecheck/e8/e825c8cce29ff1ee003cb07d375db856.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    View Blank Feedback Survey
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}
